import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CreateCategoryComponent} from './create-category/create-category.component';
import {EditCategoryComponent} from './edit-category/edit-category.component';
import {ListCategoryComponent} from './list-category/list-category.component';

const routes: Routes = [
  {
    path: 'admin/category',
    children: [
      {
        path: 'create',
        component: CreateCategoryComponent
      },
      {
        path: 'edit',
        component: EditCategoryComponent
      },
      {
        path: 'list',
        component: ListCategoryComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CategoryRoutingModule {
}
