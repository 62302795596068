import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { Component, OnInit } from '@angular/core';
import { UsersService } from '../../shared/users.service';
import { NotifyService } from '../../core/notify.service';
// import { BranchService } from '../../shared/branch.service';
import { User } from '../../models/user.model';
import { MineBranchService } from 'src/app/services/mine/mine-branch.service';

@Component({
  selector: 'teachers',
  templateUrl: './teachers.component.html',
  styleUrls: ['./teachers.component.scss']
})
export class TeachersComponent implements OnInit {
  users: any;
  branches: any;
  selectedUser = new User();
  isEditModalActive = false;
  tableData: any[] = [];
  firstInResponse: any = [];
  lastInResponse: any = [];
  prev_strt_at: any = [];
  pagination_clicked_count = 0;
  disable_next = false;
  disable_prev = true;

  constructor(private usersService: UsersService,
    // private branchService: BranchService,
    private notifyService: NotifyService,
    private firestore: AngularFirestore,
    private mineBranchesService: MineBranchService
  ) {
  }

  ngOnInit() {
    this.mineBranchesService.queryBranch({}).subscribe((branches)=>{
      // console.log('branches: ',branches);
      this.branches = branches;
      this.loadItems();
    });
  }

  loadItems() {
    this.firestore.collection('users', ref => ref
      .limit(10)
      .orderBy('createdAt', 'desc')
    ).snapshotChanges()
      .subscribe(response => {
        console.log('response: ',response)
        if (!response.length) {
          console.log('No Data Available');
          return false;
        }
        this.firstInResponse = response[0].payload.doc;
        console.log("firstInResponse: ",this.firstInResponse);
        this.lastInResponse = response[response.length - 1].payload.doc;
        console.log('lastInResponse: ',this.lastInResponse);

        this.tableData = [];
        for (const item of response) {
          this.tableData.push(item.payload.doc.data());
        }
        
        console.log('tableData: ',this.tableData)
        this.tableData = this.tableData.map((user) => {
          const branch = this.branches.find(b => b.uuid === user.branchId);
          user.branchName = branch ? branch.displayName : '-';
          return user;
        });
        this.prev_strt_at = [];
        this.pagination_clicked_count = 0;
        this.disable_next = false;
        this.disable_prev = false;
        this.push_prev_startAt(this.firstInResponse);
      }, error => {
      });
  }

  prevPage() {
    this.disable_prev = true;
    this.firestore.collection('users', ref => ref
      .orderBy('createdAt', 'desc')
      .startAt(this.get_prev_startAt())
      .endBefore(this.firstInResponse)
      .limit(10)
    ).get()
      .subscribe(response => {
        this.firstInResponse = response.docs[0];
        console.log(this.firstInResponse);
        this.lastInResponse = response.docs[response.docs.length - 1];

        this.tableData = [];
        for (const item of response.docs) {
          this.tableData.push(item.data());
        }

        this.tableData = this.tableData.map((user) => {
          const branch = this.branches.find(b => b.id === user.branchId);
          user.branchName = branch ? branch.displayName : '-';
          return user;
        });
        // Maintaing page no.
        this.pagination_clicked_count--;

        // Pop not required value in array
        this.pop_prev_startAt(this.firstInResponse);

        // Enable buttons again
        this.disable_prev = false;
        this.disable_next = false;
      }, error => {
        this.disable_prev = false;
      });
  }

  nextPage() {
    this.disable_next = true;
    this.firestore.collection('users', ref => ref
      .limit(10)
      .orderBy('createdAt', 'desc')
      .startAfter(this.lastInResponse)
    ).get()
      .subscribe(response => {

        if (!response.docs.length) {
          this.disable_next = true;
          return;
        }

        this.firstInResponse = response.docs[0];

        this.lastInResponse = response.docs[response.docs.length - 1];
        this.tableData = [];
        for (const item of response.docs) {
          this.tableData.push(item.data());
        }
        this.tableData = this.tableData.map((user) => {
          const branch = this.branches.find(b => b.id === user.branchId);
          user.branchName = branch ? branch.displayName : '-';
          return user;
        });
        this.pagination_clicked_count++;

        this.push_prev_startAt(this.firstInResponse);

        this.disable_next = false;
      }, error => {
        this.disable_next = false;
      });
  }

  // Add document
  push_prev_startAt(prev_first_doc) {
    this.prev_strt_at.push(prev_first_doc);
  }

  // Remove not required document
  pop_prev_startAt(prev_first_doc) {
    this.prev_strt_at.forEach(element => {
      if (prev_first_doc.data().id === element.data().id) {
        element = null;
      }
    });
  }

  // Return the Doc rem where previous page will startAt
  get_prev_startAt() {
    if (this.prev_strt_at.length > (this.pagination_clicked_count + 1)) {
      this.prev_strt_at.splice(this.prev_strt_at.length - 2, this.prev_strt_at.length - 1);
    }
    return this.prev_strt_at[this.pagination_clicked_count - 1];
  }


  showEditUserModal(user) {
    this.selectedUser = user;
    this.isEditModalActive = true;
  }

  closeModal() {
    this.isEditModalActive = false;
  }

  updateUser() {
    const user = this.selectedUser;
    this.usersService.updateUser(user.uid, user).then(() => {
      this.notifyService.update('Сакталды.', 'success');
      this.closeModal();
    }).catch((err) => {
      this.notifyService.update('Ката чыкты', 'error');
    });
  }

}
