import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {UserLoginComponent} from './user-login/user-login.component';
import {HomePageComponent} from './home-page/home-page.component';
import {MainNavComponent} from './main-nav/main-nav.component';
import {LoadingSpinnerComponent} from './loading-spinner/loading-spinner.component';
import {NotificationMessageComponent} from './notification-message/notification-message.component';
import {UserProfileComponent} from './user-profile/user-profile.component';
import {UserFormComponent} from './user-form/user-form.component';
import {IntensiveCheckComponent} from './intensive-check/intensive-check.component';
import {GeneralCheckComponent} from './general-check/general-check.component';
import {ExamResultsComponent} from './exam-results/exam-results.component';
import {ExamResultListComponent} from './exam-result-list/exam-result-list.component';

@NgModule({
  imports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule],
  declarations: [
    UserLoginComponent,
    HomePageComponent,
    MainNavComponent,
    LoadingSpinnerComponent,
    NotificationMessageComponent,
    UserProfileComponent,
    UserFormComponent,
    IntensiveCheckComponent,
    GeneralCheckComponent,
    ExamResultsComponent,
    ExamResultListComponent
  ],
  exports: [
    MainNavComponent,
    LoadingSpinnerComponent,
    NotificationMessageComponent,
    UserProfileComponent,
    UserFormComponent
  ]
})
export class UiModule {
}
