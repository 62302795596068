import { Component, OnInit } from '@angular/core';
import { NotifyService } from '../../../core/notify.service';
import {Location} from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { v4 } from 'uuid';

import {Students} from '../../../models/students';
import { MineGroupsService } from 'src/app/services/mine/mine-groups.service';
import { MineStudentsService } from 'src/app/services/mine/mine-students.service';


@Component({
  selector: 'create-student',
  templateUrl: './create-student.component.html',
  styleUrls: ['./create-student.component.css']
})
export class CreateStudentComponent implements OnInit {

  students: Students;
  dupStudents: any;

  groups: any;
  selectedGroup: any;
  isLoading = false;
  uuid = '';
  groupTitle = '';
  groupUuid = '';

  constructor(
    private route: ActivatedRoute,
    private _location: Location,
    private notify: NotifyService,
    private mineGroupsService: MineGroupsService,
    private mineStudentsService: MineStudentsService
  ) { 
    this.students = new Students();
    this.dupStudents = JSON.parse(JSON.stringify(this.students));
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.uuid = params.id;
      if (this.uuid) {
        this.groups = this.mineGroupsService.queryGroup({'uuid':this.uuid}).toPromise()
        .then((data)=>{
          this.groupUuid = data[0].uuid;
          this.groupTitle = data[0].title;
          return data;
        })
        .catch();
      }
    });
    // this.groups = this.mineGroupsService.queryGroup({});
    // console.log('this.groups: ',this.groups)
  }

  save(){

    this.dupStudents.uuid = v4();
    this.isLoading = true;

    this.dupStudents.name = this.students.name;
    this.dupStudents.surname = this.students.surname;
    this.dupStudents.phone = this.students.phone;
    this.dupStudents.school = this.students.school;

    this.dupStudents.groupUuid = this.groupUuid;
    this.dupStudents.groupTitle = this.groupTitle;

    this.mineStudentsService.createStudents(this.dupStudents)
    .then((result) => {

      this.mineGroupsService.queryGroup({'uuid':result.groupUuid}).toPromise()
      .then((myGroup)=> {

        this.mineGroupsService.updateGroup({...myGroup[0],'totalStudentsInGroup':Number(myGroup[0].totalStudentsInGroup) + 1})
        .then((result) => {
          // console.log('result: ',result)
        }).catch();

      });

      this.isLoading = false;
      this.notify.update('Студент сакталды.', 'success');
      this._location.back();

    }).catch((error) => {
      console.log(error);
      this.notify.update('Студент сакталбады.' + error, 'error');
    });
  }

  // onChange() {
  //   this.dupStudents.groupUuid = this.selectedGroup.uuid;
  //   this.dupStudents.groupTitle = this.selectedGroup.title;
  // }

  onCancel() {
    this._location.back();
  }

}
