import {Component, OnInit} from '@angular/core';
import { AnswerKeyIntensive} from '../../../models/answer-key';
import {NotifyService} from '../../../core/notify.service';
import {AnswerKeyIntensiveService} from '../../../shared/answer-key-intensive.service';
import {ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import { MineAnswerKeyIntensiveServiceService } from 'src/app/services/mine/mine-answer-key-intensive-service.service';

@Component({
  selector: 'edit-intensive',
  templateUrl: './edit-intensive.component.html',
  styleUrls: ['./edit-intensive.component.scss']
})
export class EditIntensiveComponent implements OnInit {
  isLoading = false;
  answerKeyIntensive: AnswerKeyIntensive;
  id = '';
  mineAnswerKeyIntensive: AnswerKeyIntensive = new AnswerKeyIntensive();

  constructor(private answerKeyIntensiveService: AnswerKeyIntensiveService,
              private route: ActivatedRoute,
              private _location: Location,
              private notify: NotifyService,
              private mineAnswerKeyIntensiveService: MineAnswerKeyIntensiveServiceService) {
  }

  onSelect(variant: string, lang: string, testType: string, index: any): void {
    this.answerKeyIntensive[lang][testType][index] = variant;
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      // console.log('ngOnInit console.log params',params)
      this.id = params.id;
      if (this.id) {
        this.answerKeyIntensiveService.getAnswerKey(this.id).valueChanges().subscribe((data) => {
          this.answerKeyIntensive = data;
          // console.log('ngOnInit data: ',data)
        });
      }
    });
  }

  saveUpdate() {
    this.isLoading = true;
    this.answerKeyIntensiveService.updateAnswerKey(this.answerKeyIntensive.uuid)
      .subscribe((QuerySnapshot) => {
      QuerySnapshot.forEach((doc) =>
        {
          doc.ref.update(this.answerKeyIntensive)
            .then(
            ()=>{
              this.isLoading = false;
              this.notify.update('Жооптор сакталды.', 'success');
              this._location.back();
            }
          ).catch((err) => {
            console.log(err)
            this.notify.update('Ката чыкты', 'error');
          });
        }
      )
    });

    this.mineAnswerKeyIntensiveService.updateAnswerKey(this.answerKeyIntensive)
      .then((data)=>console.log('done'))
      .catch((e)=>console.error(e));
  }

  onCancel() {
    this._location.back();
  }

}
