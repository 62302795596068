import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {Location} from '@angular/common';

import { NotifyService } from 'src/app/core/notify.service';
import { Groups } from 'src/app/models/groups';
import { MineGroupsService } from 'src/app/services/mine/mine-groups.service';
import { MineBranchService } from 'src/app/services/mine/mine-branch.service';
import { MineLanguageService } from 'src/app/services/mine/mine-language.service';

@Component({
  selector: 'edit-group',
  templateUrl: './edit-group.component.html',
  styleUrls: ['./edit-group.component.css']
})
export class EditGroupComponent implements OnInit {

  groups:  Groups;
  dupGroups: any;
  branches: any;
  selectedBranch: any = {uuid: ''};
  languages: any;
  selectedLanguage: any = {uuid: ''};
  isLoading = false;
  uuid = '';
  // branch:any = [];

  constructor(
    private route: ActivatedRoute,
    private _location: Location,
    private notify: NotifyService,
    private mineLanguageService: MineLanguageService,
    private mineGroupsService: MineGroupsService,
    private mineBranchesService: MineBranchService,
  ) { }

  ngOnInit(): void {
    
    this.route.params.subscribe((params) => {
      this.uuid = params.id;
      if (this.uuid) {

        this.mineLanguageService.queryLanguage({}).toPromise()
        .then((data)=>{
          this.languages = data;
          
          this.mineGroupsService.queryGroup({'uuid':this.uuid}).toPromise()
            .then((data1)=> {

              this.groups = data1[0];
              this.dupGroups = JSON.parse(JSON.stringify(this.groups));
              
              this.selectedLanguage = this.languages.find((lang) => {
                return this.groups.languageUuid === lang.uuid;
              })                
            })
            .catch((e)=>console.error(e));
            
        })
        .catch()

        this.mineBranchesService.queryBranch({}).toPromise()
          .then((data)=>{
            this.branches = data;

            this.mineGroupsService.queryGroup({'uuid':this.uuid}).toPromise()
              .then((data1)=> {

                this.groups = data1[0];
                this.dupGroups = JSON.parse(JSON.stringify(this.groups));
                
                this.selectedBranch = this.branches.find((br) => {
                  return this.groups.branchUuid === br.uuid;
                })                
              })
              .catch((e)=>console.error(e));
              
          })
          .catch()
      } // if case
    });
  }
  
  setNecessaryValues(){
    // if(this.dupGroups.branchUuid != this.selectedBranch.uuid){
      this.dupGroups.title = this.groups.title;
      this.dupGroups.place = this.groups.place;
    // }
  }

  save() {
    console.log('this.dupGroups: ',this.dupGroups)
    if (!this.dupGroups.languageUuid) {
      this.notify.update('Тилди танданыз', 'error');
      return;
    }
    if (!this.dupGroups.branchUuid) {
      this.notify.update('Филиалды танданыз', 'error');
      return;
    }

    this.isLoading = true;
    this.setNecessaryValues();
    
    this.mineGroupsService.updateGroup(this.dupGroups)
    .then((result) => {
      console.log('result: ',result)
      this.isLoading = false;
      this.notify.update('Группа сакталды.', 'success');
      this._location.back();
      
    }).catch((error) => {
      console.log(error);
    });
  }

  onCancel() {
    this._location.back();
  }

  onChangeLanguage(){
    this.dupGroups.languageUuid = this.selectedLanguage.uuid;
    this.dupGroups.languageTitle = this.selectedLanguage.title;
  }

  onChangeBranch() {
    this.dupGroups.branchUuid = this.selectedBranch.uuid;
    this.dupGroups.branchTitle = this.selectedBranch.displayName;
  }
}
