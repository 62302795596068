import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import 'firebase/firestore';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';
import {NotifyService} from './notify.service';

import {Observable, of} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {User} from '../models/user.model';

const authErrorCodes = {
  'auth/user-not-found': 'Мындай колдонуучу жок',
  'auth/wrong-password': 'Сыр сөз туура эмес',
};

@Injectable()
export class AuthService {
  user: Observable<User | null>;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    private notify: NotifyService
  ) {
    this.user = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
    );
  }

  emailSignUp(email: string, password: string, displayName: string, branchId: string) {
    return this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then(credential => {
        this.notify.update('Сиз катталдыңыз!', 'success');
        const user = Object.assign({}, credential.user);
        user.displayName = displayName;
        return this.updateUserData(user, branchId); // if using firestore
      })
      .catch(error => this.handleError(error));
  }

  //// Email/Password Auth ////
  emailLogin(email: string, password: string) {
    return this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        this.notify.update('Кош келиниз!', 'success');
        return Promise.resolve();
      })
      .catch(error => {
        error.message = authErrorCodes[error.code] || error.message;
        this.handleError(error);
        return Promise.reject(error);
      });
  }

  // Sends email allowing user to reset password
  resetPassword(email: string) {
    return this.afAuth
      .sendPasswordResetEmail(email)
      .then(() => this.notify.update('Сыр сөз эл. адреске жөнөтүлдү', 'info'))
      .catch(error => this.handleError(error));
  }

  signOut() {
    this.afAuth.signOut().then(() => {
      this.router.navigate(['/']);
    });
  }

  // If error, console log and notify user
  private handleError(error: Error) {
    console.error(error);
    this.notify.update(error.message, 'error');
  }

  // Sets user data to firestore after succesful login
  private updateUserData(user: any, branchId: string) {
    const userRef: AngularFirestoreDocument<User> = this.afs.doc(
      `users/${user.uid}`
    );

    const data: User = {
      uid: user.uid,
      email: user.email || null,
      displayName: user.displayName || 'Аты жок',
      role: 'Teacher',
      branchId: branchId || '',
      activated: false,
      createdAt: new Date()
    };
    return userRef.set(data);
  }
}
