import {Component, OnInit} from '@angular/core';
// import {AnswerKeyService} from '../../shared/answer-key.service';
import {NotifyService} from '../../core/notify.service';
// import {ActivatedRoute, Router} from '@angular/router';
import { MineAnswerkeysService } from 'src/app/services/mine/mine-answerkeys.service';

@Component({
  selector: 'list-answer-key',
  templateUrl: './list-answer-key.component.html',
  styleUrls: ['./list-answer-key.component.scss']
})
export class ListAnswerKeyComponent implements OnInit {
  isLoading = false;
  answerKeys: any;
  isModalActive = false;
  selectedAnswerKeyUuid = '';

  constructor(//private answerKeyService: AnswerKeyService,
              //private router: Router,
              //private route: ActivatedRoute,
              private notify: NotifyService,
              private mineAnswerKeysService: MineAnswerkeysService) {
  }

  ngOnInit() {
    this.answerKeys = this.mineAnswerKeysService.queryAnswerkeys({});
    // this.answerKeys = this.answerKeyService.getData();
  }

  closeModal() {
    this.isModalActive = false;
  }

  showPopup(answerKeyUuid) {
    this.selectedAnswerKeyUuid = answerKeyUuid;
    this.isModalActive = !this.isModalActive;
  }

  delete() {
    this.isLoading = true;
    this.closeModal();

    this.mineAnswerKeysService.removeByAnswerkeysUuid(this.selectedAnswerKeyUuid)
      .then((data)=>{
        console.log('mineAnswerKeys removed');
        this.isLoading = false;
        this.notify.update('Жооптор өчүрүлдү.', 'success');
        this.answerKeys = this.mineAnswerKeysService.queryAnswerkeys({});
      })
      .catch((e)=>console.error(e));

    // this.answerKeyService.deleteNote(this.selectedAnswerKeyUuid)
    // .subscribe((QuerySnapshot) => {
    //   QuerySnapshot.forEach((doc) =>
    //     {
    //       doc.ref.delete().then(
    //         ()=>{
    //           this.isLoading = false;
    //           this.notify.update('Жооптор өчүрүлдү.', 'success');
    //         }
    //       ).catch((e)=>console.error(e))
    //     }
    //   )
    // });
  }

}
