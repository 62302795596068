import {Component, OnInit} from '@angular/core';
import {AnswerKey} from '../../models/answer-key';
import {NotifyService} from '../../core/notify.service';
// import {AnswerKeyService} from '../../shared/answer-key.service';
import {Location} from '@angular/common';
import { MineAnswerkeysService } from 'src/app/services/mine/mine-answerkeys.service';
import { v4 } from 'uuid';

@Component({
  selector: 'create-answer-key',
  templateUrl: './create-answer-key.component.html',
  styleUrls: ['./create-answer-key.component.scss']
})
export class CreateAnswerKeyComponent implements OnInit {
  isLoading = false;
  answerKey: AnswerKey = new AnswerKey();

  constructor(private notify: NotifyService,
              private _location: Location,
              // private answerKeyService: AnswerKeyService,
              private mineAnswerkeysService: MineAnswerkeysService) {
  }

  onSelect(variant: string, lang: string, testType: string, index: any): void {
    this.answerKey[lang][testType][index] = variant;
  }

  ngOnInit() {

  }

  save() {
    this.answerKey.uuid = v4();
    this.isLoading = true;

    this.mineAnswerkeysService.createAnswerkeys(this.answerKey)
    .then((mineAnswerKey) => {
      this.isLoading = false;
        this.notify.update('Жооптор сакталды.', 'success');
      console.log('mineAnswerKey is saved: ',mineAnswerKey);
    }).catch((error) => {
      console.log(error);
    });

    this._location.back();

    // this.answerKeyService.createAnswerKey(this.answerKey)
    //   .then((answerKey) => {
    //     this.isLoading = false;
    //     this.notify.update('Жооптор сакталды.', 'success');
    //     console.log('answerKey is saved: ',answerKey);
    //     this.mineAnswerkeysService.createAnswerkeys(this.answerKey)
    //       .then((mineAnswerKey) => {
    //         console.log('mineAnswerKey is saved: ',mineAnswerKey);
    //       }).catch((error) => {
    //         console.log(error);
    //       });
    //   }).catch((error) => {
    //   console.log(error);
    // });

  }

  onCancel() {
    this._location.back();
  }
}
