export class Groups {
    uuid = '';
    title = '';
    place = '';
    totalStudentsInGroup = '';
    branchUuid = '';
    branchTitle = '';
    languageUuid = '';
    languageTitle = '';
    createdAt: Date;
    createdAtString = '';
}