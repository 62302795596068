import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {AuthGuard} from './core/auth.guard';
import {UserLoginComponent} from './ui/user-login/user-login.component';
import {HomePageComponent} from './ui/home-page/home-page.component';
import {GeneralCheckComponent} from './ui/general-check/general-check.component';
import {IntensiveCheckComponent} from './ui/intensive-check/intensive-check.component';
import {ExamResultsComponent} from './ui/exam-results/exam-results.component';
import {ExamResultListComponent} from './ui/exam-result-list/exam-result-list.component';

const routes: Routes = [
  {path: '', component: HomePageComponent, canActivate: [AuthGuard]},
  {path: 'login', component: UserLoginComponent},
  {path: 'intensive', component: IntensiveCheckComponent},
  {path: 'general', component: GeneralCheckComponent},
  {path: 'exam-result-list', component: ExamResultListComponent},
  {path: 'exam-results/:id', component: ExamResultsComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
