import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StudentsResultsService {
  path = 'student-results';
  // baseUrl = 'http://localhost:3001';
  // baseUrl = environment.baseURL;
  baseUrl = 'https://check-api.secom.kg';

  constructor(private http: HttpClient) {
  }

  query(body): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + `/api/v1/${this.path}/query`, body);
  }

  create(obj: any): any {
    // Remove unused fields and store
    obj.answers = undefined;
    obj.points.math = undefined;
    obj.points.analogiya = undefined;
    obj.points.okuuTushunuu = undefined;
    obj.points.grammatika = undefined;

    // console.log(this.baseUrl + `/api/v1/${this.path}`)

    return this.http.post<any>(
      this.baseUrl + `/api/v1/${this.path}`,
      obj,
      {responseType: 'json', observe: 'body'}
    ).toPromise();
  }

  removeByExamUuid(examUuid): any {
    return this.http.delete<any[]>(this.baseUrl + `/api/v1/${this.path}/examUuid/${examUuid}`).toPromise();
  }

  removeById(examUuid): any {
    return this.http.delete<any[]>(this.baseUrl + `/api/v1/${this.path}/${examUuid}`).toPromise();
  }

  update(studentResult: any): any {
    return this.http.put<any[]>(this.baseUrl + `/api/v1/${this.path}`, studentResult).toPromise();
  }

  // query(body): Observable<any[]> {
  //   return this.http.post<any[]>(`${environment.baseURL}/api/v1/${this.path}/query`, body);
  // }

  // create(obj: any): any {
  //   // Remove unused fields and store
  //   obj.answers = undefined;
  //   obj.points.math = undefined;
  //   obj.points.analogiya = undefined;
  //   obj.points.okuuTushunuu = undefined;
  //   obj.points.grammatika = undefined;

  //   // console.log(`${environment.baseURL}/api/v1/${this.path}`)

  //   return this.http.post<any>(
  //     `${environment.baseURL}/api/v1/${this.path}`,
  //     obj,
  //     {responseType: 'json', observe: 'body'}
  //   ).toPromise();
  // }

  // removeByExamUuid(examUuid): any {
  //   return this.http.delete<any[]>(`${environment.baseURL}/api/v1/${this.path}/examUuid/${examUuid}`).toPromise();
  // }

  // removeById(examUuid): any {
  //   return this.http.delete<any[]>(`${environment.baseURL}/api/v1/${this.path}/${examUuid}`).toPromise();
  // }

  // update(studentResult: any): any {
  //   return this.http.put<any[]>(`${environment.baseURL}/api/v1/${this.path}`, studentResult).toPromise();
  // }
}
