import { Component, OnInit } from '@angular/core';
import { Category } from '../../../models/category';
import { AnswerKeyService } from '../../../shared/answer-key.service';
import { CategoryService } from '../../../shared/category.service';
import { NotifyService } from '../../../core/notify.service';
import {Location} from '@angular/common';
import { MineCategoryService } from 'src/app/services/mine/mine-category.service';
import { v4 } from 'uuid';
import { MineAnswerkeysService } from 'src/app/services/mine/mine-answerkeys.service';

@Component({
  selector: 'create-category',
  templateUrl: './create-category.component.html',
  styleUrls: ['./create-category.component.scss']
})
export class CreateCategoryComponent implements OnInit {
  category: Category;
  objectKeys = Object.keys;
  predmetter = ['math', 'analogiya', 'okuuTushunuu', 'grammatika'];
  predmetterName = ['Математика', 'АНАЛОГИЯЛАР ЖАНА СҮЙЛӨМДӨРДҮ ТОЛУКТОО',
    'ЭНЕ ТИЛИНДЕ ОКУУ ЖАНА ТҮШҮНҮҮ', 'ЭНЕ ТИЛИНИН ПРАКТИКАЛЫК ГРАММАТИКАСЫ'];
  predmetterNameRu = ['Математика', 'АНАЛОГИИ И ДОПОЛНЕНИЕ ПРЕДЛОЖЕНИЙ', 'ЧТЕНИЕ И ПОНИМАНИЕ', 'ПРАКТИЧЕСКАЯ ГРАММАТИКА РУССКОГО ЯЗЫКА'];
  dupCategory: any;
  selectedAnswerKey: any;
  answerKeys: any;
  isSaving = false;

  constructor(//private answerKeyService: AnswerKeyService,
              //private categoryService: CategoryService,
              private _location: Location,
              private notify: NotifyService,
              private mineCategoryService: MineCategoryService,
              private mineAnswerKeysService: MineAnswerkeysService
              ) {
    this.category = new Category();
    this.dupCategory = JSON.parse(JSON.stringify(this.category));
  }

  ngOnInit() {
    
    this.answerKeys = this.mineAnswerKeysService.queryAnswerkeys({});

    console.log('ngOnInit: ', this.answerKeys);
    // this.categoryService.getCategoryByUuid('f9fe3fa9-1c30-48fd-8ce9-87d8d1637009').subscribe(
    //     value => {
    //       this.byUuid = value;
    //     }
    //   );

    
    // this.answerKeyService.getData().subscribe((data) => {
    //   this.answerKeys = data;
    // });
    
  }

  onSearchChange(searchValue: string, type: string, key: string, jj, ii, lang: string) {
    // [(ngModel)]="this.category.kg.math[keys].subCategories[jj].questions[ii]"
    this.dupCategory[lang][type][key].subCategories[jj].questions[ii] = searchValue;
  }

  onTopicChange(topicName: string, type: string, key: string, jj, lang: string) {
    // [(ngModel)]="this.category.kg.math[keys].subCategories[jj].questions[ii]"
    this.dupCategory[lang][type][key].subCategories[jj].name = topicName;
  }

  saveCategory() {
    if (!this.dupCategory.answerKeyUuid) {
      this.notify.update('Тестти танданыз', 'error');
      return;
    }

    this.removeUnusedFields();
    this.dupCategory.uuid = v4();
    this.isSaving = true;

    this.mineCategoryService.createCategory(this.dupCategory)
    .then((result) => {
      // console.log('category created: ',result)

      this.selectedAnswerKey.categoryUuid = result.uuid;
      this.mineAnswerKeysService.updateAnswerkeys(this.selectedAnswerKey)
      .then((data)=>{
        // console.log('updated mineAnswerKeys: ',data)
      })
      .catch((e)=>console.error(e));

      this.isSaving = false;
      this.notify.update('Категориялар сакталды.', 'success');
      this._location.back();

    }).catch((error) => {
      console.log(error);
      this.notify.update('Категориялар сакталбады.' + error, 'error');
    });
    

    // this.categoryService.createCategory(this.dupCategory)
    //   .then((result) => {
    //     this.isSaving = false;
    //     this.notify.update('Категориялар сакталды.', 'success');
    //     this.answerKeyService
    //       .getAnswerKey(this.dupCategory.answerKeyId)
    //       .update({ categoryId: result.id })
    //       .then(() => {
    //         console.log('categoryId saved in firebase: ', result.id);
    //       })
    //       .catch((e)=>console.error(e));
    //     // this._location.back();
    //   }).catch((error) => {
    //   console.log(error);
    //   this.notify.update('Категориялар сакталбады.' + error, 'error');
    // });
  }

  onChange() {
    this.dupCategory.answerKeyName = this.selectedAnswerKey.testName;
    this.dupCategory.answerKeyNumber = this.selectedAnswerKey.testNumber;
    this.dupCategory.answerKeyUuid = this.selectedAnswerKey.uuid;
  }

  onCancel() {
    this._location.back();
  }

  private removeUnusedFields() {
    ['kg', 'ru'].forEach((group) => {
      this.predmetter.forEach((predmet) => {
        Object.keys(this.dupCategory[group][predmet]).forEach((subcat) => {
          this.dupCategory[group][predmet][subcat].subCatNames = null;
        });
      });
    });
  }

}

