import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CreateAnswerKeyComponent} from './create-answer-key/create-answer-key.component';
import {EditAnswerKeyComponent} from './edit-answer-key/edit-answer-key.component';

const routes: Routes = [
  {
    path: 'answer-keys',
    children: [
      {
        path: 'create',
        component: CreateAnswerKeyComponent
      },
      {
        path: 'edit',
        component: EditAnswerKeyComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AnswerKeyRoutingModule {
}
