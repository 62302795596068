import {Component, OnInit} from '@angular/core';
// import {BranchService} from '../../shared/branch.service';
import {ExamResult} from '../../models/exam-result.model';
import {NotifyService} from '../../core/notify.service';
import {AuthService} from '../../core/auth.service';
import {ExamResultsService} from '../../services/exam-results.service';
import {StudentsResultsService} from '../../services/students-results.service';
import { MineBranchService } from 'src/app/services/mine/mine-branch.service';

@Component({
  selector: 'exam-result-list',
  templateUrl: './exam-result-list.component.html',
  styleUrls: ['./exam-result-list.component.css']
})
export class ExamResultListComponent implements OnInit {
  selectedExam = new ExamResult();
  isDeleteModalActive = false;
  currentUser: any;
  branches: any;
  tableData: any[] = [];

  constructor(private auth: AuthService,
              private examResultsService: ExamResultsService,
              // private branchService: BranchService,
              private mineBranchService: MineBranchService,
              private studentResultsService: StudentsResultsService,
              private notifyService: NotifyService) {
  }

  ngOnInit(): void {
    this.auth.user.subscribe((data) => {
      this.currentUser = data;
      // this.branchService.getData().subscribe((branches) => {
      this.mineBranchService.queryBranch({}).subscribe((branches) => {
        
        this.branches = branches;
        this.examResultsService.query({}).subscribe((examResults) => {
          console.log('examResults length: ',examResults.length);
          // console.log('currentUser: ',this.currentUser)
          if (this.currentUser.role !== 'Admin') {
            // console.log('examResults: ',examResults)
            examResults = examResults.filter(exam => exam.branchId === this.currentUser.branchId);
            console.log('if Admin: ',examResults);
          }

          this.tableData = examResults.map((exam) => {
            const branch = this.branches.find(br => br.uuid === exam.branchId);
            exam.branchName = branch ? branch.displayName : '--';
            return exam;
          });
          console.log('tD: ',this.tableData)
        });
      });

    });
  }
  // {"examUuid":"dcae455b-19ff-40fe-9cfe-5a2532d3f12d"}
  showDeleteModal(exam) {
    this.selectedExam = exam;
    this.isDeleteModalActive = true;
  }

  deleteExam() {
    this.examResultsService.remove(this.selectedExam.uuid)
      .then((data) => {
        this.studentResultsService.removeByExamUuid(this.selectedExam.uuid).then((res) => {
          console.log('students removed too');
          console.log(res);
        });
        this.closeModal();
        this.notifyService.update('Ийгиликтүү өчүрүлдү.', 'success');
      })
      .catch((err) => {
        console.log(err);
        this.notifyService.update('Ката чыкты.', 'error');
      });
  }

  closeModal() {
    this.isDeleteModalActive = false;
  }
}
