import {Component, OnInit} from '@angular/core';
// import {AnswerKeyService} from '../../shared/answer-key.service';
import {AnswerKey} from '../../models/answer-key';
import {NotifyService} from '../../core/notify.service';
import {ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import { MineAnswerkeysService } from 'src/app/services/mine/mine-answerkeys.service';

@Component({
  selector: 'edit-answer-key',
  templateUrl: './edit-answer-key.component.html',
  styleUrls: ['./edit-answer-key.component.scss']
})
export class EditAnswerKeyComponent implements OnInit {
  answerkey:AnswerKey;
  answerKey: AnswerKey;
  isLoading = false;

  id = '';
  uuid = '';

  constructor(// private answerKeyService: AnswerKeyService,
              private route: ActivatedRoute,
              private _location: Location,
              private notify: NotifyService,
              private mineAnswerkeysService: MineAnswerkeysService) {
  }

  onSelect(variant: string, lang: string, testType: string, index: any): void {
    // console.log('selected: ', variant, lang, index);
    this.answerKey[lang][testType][index] = variant;
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      // console.log(params);
      this.uuid = params.id;
      if (this.uuid) {
        this.mineAnswerkeysService.queryAnswerkeys({'uuid':this.uuid}).toPromise()
        .then((data)=> {
          console.log(data);
          this.answerkey = data[0];
          console.log(data[0]);
          this.answerKey = JSON.parse(JSON.stringify(this.answerkey));
        })
        .catch((e)=>console.error(e));

        // this.answerKeyService.getAnswerKeyByUuid(this.uuid).subscribe(
        //   (data) => {this.answerKey = data;}
        // )
        // this.answerKeyService.getAnswerKey(this.id).valueChanges().subscribe((data) => {
        //   this.answerKey = data;
        // });
      }
    });
  }

  saveUpdate() {
    // console.log('answerKey in edit: ',this.answerKey);
    this.isLoading = true;

    this.mineAnswerkeysService.updateAnswerkeys(this.answerKey)
    .then((data)=>{
      this.isLoading = false;
      this.notify.update('Жооптор сакталды.', 'success');
      console.log('updated mineAnswerKeys: ',data)
    })
    .catch((e)=>console.error(e));

    this._location.back();

    // this.answerKeyService.updateAnswerKey(this.answerKey.uuid)
    //   .subscribe((QuerySnapshot) => {
    //     QuerySnapshot.forEach((doc) =>
    //       {
    //         doc.ref.update(this.answerKey)
    //           .then(
    //           ()=>{
    //             this.isLoading = false;
    //             this.notify.update('Жооптор сакталды.', 'success');

    //             this.mineAnswerkeysService.updateAnswerkeys(this.answerKey)
    //             .then((data)=>console.log('updated mineAnswerKeys: ',data))
    //             .catch((e)=>console.error(e));

    //           }
    //         ).catch((err) => {
    //           console.log(err)
    //           this.notify.update('Ката чыкты', 'error');
    //         });
    //       }
    //     )
    //   });
      
  }

  onCancel() {
    this._location.back();
  }

}
