import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Student} from '../../models/student';
import {NotifyService} from '../../core/notify.service';
import {StudentsResultsService} from '../../services/students-results.service';

@Component({
  selector: 'exam-results',
  templateUrl: './exam-results.component.html',
  styleUrls: ['./exam-results.component.css']
})
export class ExamResultsComponent implements OnInit {
  selectedStudent = new Student();
  isDeleteModalActive = false;
  isEditModalActive = false;
  selectedExamUuid: string;
  students: Student[] = [];

  constructor(private studentResultsService: StudentsResultsService,
              private notifyService: NotifyService,
              private route: ActivatedRoute) {

    // this.route.paramMap.subscribe(params => {
      // this.selectedExamUuid = params.get('id');
    this.route.params.subscribe((params) => {
      this.selectedExamUuid = params.id;
      console.log('params: ',params)
      this.studentResultsService.query({examUuid: this.selectedExamUuid})
        .subscribe((res) => {
          this.students = res;
        });
    });
  }

  ngOnInit(): void {
  }

  showDeleteModal(student) {
    this.selectedStudent = student;
    this.isDeleteModalActive = true;
  }

  showEditModal(student) {
    this.selectedStudent = student;
    this.isEditModalActive = true;
  }

  updateStudent() {
    this.studentResultsService.update(this.selectedStudent)
      .then((data) => {
        this.closeModal();
        this.notifyService.update('Ийгиликтүү сакталды', 'success');
      })
      .catch(err => {
        console.log(err);
        this.notifyService.update('Ката чыкты', 'error');
      });
  }

  deleteStudent() {
    this.studentResultsService.removeById(this.selectedStudent.uuid)
      .then((data) => {
        this.closeModal();
        this.notifyService.update('Ийгиликтүү өчүрүлдү', 'success');
      })
      .catch(err => {
        console.log(err);
        this.notifyService.update('Ката чыкты', 'error');
      });
  }

  closeModal() {
    this.isDeleteModalActive = false;
    this.isEditModalActive = false;
  }
}
