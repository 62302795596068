export class Students {
    uuid: string;
    groupUuid = '';
    groupTitle = '';
    name = '';
    surname = '';
    phone = '';
    school = '';
    createdAt: Date;
    createdAtString = '';    
  }