import {Injectable} from '@angular/core';
import { AnswerKey } from '../models/answer-key';
import {OpticReaderConf as ORF, Student} from '../models/student';

@Injectable({
  providedIn: 'root'
})
export class StudentService {

  constructor() {
  }

  /**
   * @param row
   * generate Student data from row string
   */
  public generateStudent(row: any): Student {
    const student = new Student();
    // console.log('student in generateStudent: ',student);
    const charCodeMap = {
      65: 1040,
      66: 1041,
      86: 1042,
      71: 1043,
      68: 1044,
      69: 1045,
      74: 1046,
      90: 1047,
      73: 1048,
      89: 1049,
      75: 1050,
      76: 1051,
      77: 1052,
      78: 1053,
      79: 1054,
      80: 1055,
      82: 1056,
      83: 1057,
      84: 1058,
      85: 1059,
      70: 1060,
      72: 1061,
      42: 42,
      91: 1063, // CH
      36: 1064, // SH
      87: 1198,
      38: 1067, // yy
      125: 1068,  // miagkiy
      81: 1069,
      88: 1070,
      123: 1071, // ia
      32: 32,
      0: 0
    };
    const surname = row.substr(0, ORF.surname).trim();
    const name = row.substr(ORF.surname, ORF.name).trim();
    let kirilName = '';
    for (let i = 0; i < name.length; i++) {
      if (name[i]) {
        kirilName += String.fromCharCode(charCodeMap[name[i].charCodeAt(0)]);
      }
    }

    let kirilSurname = '';
    for (let i = 0; i < surname.length; i++) {
      if (surname[i]) {
        kirilSurname += String.fromCharCode(charCodeMap[surname[i].charCodeAt(0)]);
      }
    }
    // console.log('kirilSur/Name: ', kirilName, ' ', kirilSurname);
 

    student.surname = kirilSurname;
    student.name = kirilName;
    student.phone = row.substr(ORF.surname + ORF.name, ORF.phone).trim();
    student.registrationNumber = row.substr(ORF.surname + ORF.name + ORF.phone, ORF.registrationNumber).trim();
    student.testNumber = row.substr(ORF.surname + ORF.name + ORF.phone + ORF.registrationNumber, ORF.testNumber).trim();
    student.schoolNumber = row.substr(ORF.surname + ORF.name + ORF.phone + ORF.registrationNumber +
      ORF.testNumber, ORF.schoolNumber).trim();
    student.group = row.substr(ORF.surname + ORF.name + ORF.phone + ORF.registrationNumber +
      ORF.testNumber + ORF.schoolNumber, ORF.group).trim();
      
    student.answers.math = row.substr(ORF.surname + ORF.name + ORF.phone + ORF.registrationNumber
      + ORF.testNumber + ORF.schoolNumber + ORF.group, ORF.math).split('');
    student.answers.analogiya = row.substr(ORF.surname + ORF.name + ORF.phone + ORF.registrationNumber
      + ORF.testNumber + ORF.schoolNumber + ORF.group + ORF.math, ORF.analogy).split('');

    student.answers.okuuTushunuu = row.substr(ORF.surname + ORF.name + ORF.phone + ORF.registrationNumber
      + ORF.testNumber + ORF.schoolNumber + ORF.group + ORF.math + ORF.analogy, ORF.reading).split('');

    student.answers.grammatika = row.substr(ORF.surname + ORF.name + ORF.phone + ORF.registrationNumber + ORF.testNumber
      + ORF.schoolNumber + ORF.group + ORF.math + ORF.analogy + ORF.reading, ORF.grammar).split('');
      
      // console.log('student in generateStudent: ',student.answers.math);
    return student;
  }

  public evaluateTests(students: Student[], selectedAnswerKey: any): Student[] {

    const result = students.map((student) => this.evaluateSingleResult(student, selectedAnswerKey));

    return result;
  }

  private evaluateSingleResult(student: Student, selectedAnswerKey: any) {
    
    const group = this.identifyGroup(student.group);
    Object.entries(selectedAnswerKey[group]).forEach((ar) => {
      const key = ar[0];
      const answerSheet = ar[1];
      // console.log('key: ',key);
      // console.log('answerSheet: ',answerSheet)
      // console.log('student.answers in evaluateSingleResult: ',student.answers);
      // EVALUATE STUDENTS RESULTS
      let correct = 0;
      student.answers[key].forEach((value, index) => {
        if (this.isQuestionMarkedCorrect(value, answerSheet[index])) {   // students choice was correct
          student.points[key][index] = '1';
          correct++;
        } else {
          student.points[key][index] = '0';
        }
      });
      student.points[key + 'Total'] = correct;
    });

    
    return student;
  }

  private isQuestionMarkedCorrect(value: any, answerSheetElement: any) {
    const answerCharCode = answerSheetElement.charCodeAt(0);
    const studentCharCode = value.charCodeAt(0);

    const charCodeMap = {
      1040: 65,
      1041: 66,
      1042: 67,
      1043: 68,
      1044: 69,
    };

    return studentCharCode === charCodeMap[answerCharCode];
  }

  private identifyGroup(group: string) {
    if (group === 'R') {
      return 'ru';
    }
    return 'kg';
  }
}
