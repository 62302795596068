export class SubCategory {
  name: string;
  questions: Array<string>;

  constructor(name: string, size: number) {
    this.name = name;
    this.questions = Array<string>(size);
    // this.questions.fill('');
  }
}

export const CONST = {
  mathSize: 25
};

export const CategoryConst = {
  kg: {
    math: {
      algebra: {
        name: 'Алгебра',
        subCatNames: [
          'Тема 1',
          'Тема 2',
          'Тема 3',
          'Тема 4',
          'Тема 5',
          'Тема 6',
          'Тема 7',
          'Тема 8',
          'Тема 9',
          'Тема 10',
          'Тема 11',
          'Тема 12',
          'Тема 13',
          'Тема 14',
          'Тема 15',
          'Тема 16',
          'Тема 17',
          'Тема 18',
          'Тема 19',
          'Тема 20',
          'Тема 21',
          'Тема 22'
        ],
        maxSize: 25,
      },
      geometry: {
        name: 'Геометрия',
        subCatNames: [
          'Тема 1',
          'Тема 2',
          'Тема 3',
          'Тема 4',
          'Тема 5'
        ],
        maxSize: 25,
      },
      maalymatmnishtoo: {
        name: 'Маалымат менен иштөө',
        subCatNames: [
          'Тема 1',
          'Тема 2'
        ],
        maxSize: 25,
      }
    },
    analogiya: {
      analogiyalar: {
        name: 'Аналогиялар',
        subCatNames: [
          'Тема 1',
          'Тема 2',
          'Тема 3',
          'Тема 4',
          'Тема 5',
          'Тема 6',
          'Тема 7',
          'Тема 8',
          'Тема 9',
          'Тема 10',
          'Тема 11',
          'Тема 12',
          'Тема 13',
          'Тема 14',
          'Тема 15',
          'Тема 16'
        ],
        maxSize: 25,
      },
      suilomdordutoluktoo: {
        name: 'Сүйлөмдөрдү толуктоо',
        subCatNames: [
          'Тема 1',
          'Тема 2',
          'Тема 3',
        ],
        maxSize: 25,
      },
    },
    okuuTushunuu: {
      okuu: {
        name: 'ЭНЕ ТИЛИНДЕ ОКУУ ЖАНА ТҮШҮНҮҮ',
        subCatNames: [
          'Тема 1',
          'Тема 2',
          'Тема 3',
          'Тема 4',
          'Тема 5',
          'Тема 6'
        ],
        maxSize: 25,
      },
    },
    grammatika: {
      leksika: {
        name: 'Лексика',
        subCatNames: [
          'Тема 1',
          'Тема 2',
        ],
        maxSize: 25,
      },
      morfologiya: {
        name: 'Морфология',
        subCatNames: [
          'Тема 1',
          'Тема 2',
          'Тема 3'
        ],
        maxSize: 25,
      },
      sintaksis: {
        name: 'Синтаксис',
        subCatNames: [
          'Тема 1',
          'Тема 2',
          'Тема 3',
          'Тема 4'
        ],
        maxSize: 25,
      },
      stilistika: {
        name: 'Стилистика',
        subCatNames: [
          'Тема 1',
        ],
        maxSize: 25,
      },
      kepMadaniyaty: {
        name: 'Кеп маданияты',
        subCatNames: [
          'Тема 1',
        ],
        maxSize: 25,
      },
      keptinTurloru: {
        name: 'Кептин түрлөрү',
        subCatNames: [
          'Тема 1',
        ],
        maxSize: 25,
      },
    }
  },
  ru: {
    math: {
      algebra: {
        name: 'Алгебра',
        subCatNames: [
          'Тема 1',
          'Тема 2',
          'Тема 3',
          'Тема 4',
          'Тема 5',
          'Тема 6',
          'Тема 7',
          'Тема 8',
          'Тема 9',
          'Тема 10',
          'Тема 11',
          'Тема 12',
          'Тема 13',
          'Тема 14',
          'Тема 15',
          'Тема 16',
          'Тема 17',
          'Тема 18',
          'Тема 19',
          'Тема 20',
          'Тема 21',
          'Тема 22'
        ],
        maxSize: 25,
      },
      geometry: {
        name: 'Геометрия',
        subCatNames: [
          'Тема 1',
          'Тема 2',
          'Тема 3',
          'Тема 4',
          'Тема 5',
        ],
        maxSize: 25,
      },
      maalymatmnishtoo: {
        name: 'Работа с данными',
        subCatNames: [
          'Тема 1',
          'Тема 2'
        ],
        maxSize: 25,
      }
    },
    analogiya: {
      analogiyalar: {
        name: 'Аналогии',
        subCatNames: [
          'Тема 1',
          'Тема 2',
          'Тема 3',
          'Тема 4',
          'Тема 5',
          'Тема 6',
          'Тема 7',
          'Тема 8',
          'Тема 9',
          'Тема 10',
          'Тема 11',
          'Тема 12',
          'Тема 13',
          'Тема 14',
          'Тема 15',
          'Тема 16'
        ],
        maxSize: 25,
      },
      suilomdordutoluktoo: {
        name: 'Дополнение предложений',
        subCatNames: [
          'Тема 1',
          'Тема 2',
          'Тема 3',
        ],
        maxSize: 25,
      },
    },
    okuuTushunuu: {
      okuu: {
        name: 'ЧТЕНИЕ И ПОНИМАНИЕ',
        subCatNames: [
          'Тема 1',
          'Тема 2',
          'Тема 3',
          'Тема 4',
          'Тема 5',
          'Тема 6',
        ],
        maxSize: 25,
      },
    },
    grammatika: {
      leksika: {
        name: 'Лексика',
        subCatNames: [
          'Тема 1',
          'Тема 2',
        ],
        maxSize: 25,
      },
      morfologiya: {
        name: 'Морфология',
        subCatNames: [
          'Тема 1',
          'Тема 2',
        ],
        maxSize: 25,
      },
      sintaksis: {
        name: 'Синтаксис',
        subCatNames: [
          'Тема 1',
          'Тема 2',
          'Тема 3',
          'Тема 4',
          'Тема 5',
        ],
        maxSize: 25,
      },
      stilistika: {
        name: 'Стилистика',
        subCatNames: [
          'Тема 1',
        ],
        maxSize: 25,
      },
      kepMadaniyaty: {
        name: 'Культура речи',
        subCatNames: [
          'Тема 1',
        ],
        maxSize: 25,
      },
      keptinTurloru: {
        name: 'Стилистические фигуры',
        subCatNames: [
          'Тема 1',
        ],
        maxSize: 25,
      },
    }
  }
};

export class CategoryGroup {
  group: string;
  groupName = 'Русская группа';
  math: any = {};
  analogiya: any = {};
  okuuTushunuu: any = {};
  grammatika: any = {};

  constructor(group: string) {
    this.group = group;
    if (this.group === 'kg') {
      this.groupName = 'Кыргыз группа';
    }
    this.math = {};
    this.analogiya = {};
    this.okuuTushunuu = {};
    this.grammatika = {};
    this.createGroup();
  }

  private createGroup() {
    Object.keys(CategoryConst[this.group]).forEach((boluk) => {
      Object.keys(CategoryConst[this.group][boluk])
        .forEach((key) => {
          const obj = {
            name: CategoryConst[this.group][boluk][key].name,
            subCatNames: CategoryConst[this.group][boluk][key].subCatNames,
            maxSize: CategoryConst[this.group][boluk][key].maxSize,
            subCategories: this.generateSubCategory(CategoryConst[this.group][boluk][key].subCatNames,
              CategoryConst[this.group][boluk][key].maxSize)
          };
          this[boluk][key] = obj;
        });
    });
  }

  private generateSubCategory(subCatNames: (string)[] | (string)[] | (string)[] | any, maxSize: number) {
    const subcats = [];
    subCatNames.map((name) => {
      subcats.push(new SubCategory(name, maxSize));
    });
    return subcats;
  }
}

export class Category {
  uuid: string;
  answerKeyNumber: number;
  answerKeyName: string;
  answerKeyId: string;
  answerKeyUuid: string;

  kg: CategoryGroup = new CategoryGroup('kg');
  ru: CategoryGroup = new CategoryGroup('ru');

  createdAt: Date = new Date();
}
