import { Component } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss']
})
export class MainNavComponent {

  show = false;
  loggedIn = false;

  constructor(private auth: AuthService,
              private router: Router) {
    this.auth.user.subscribe((data) => {
      if (data) {
        this.loggedIn = true;
      } else {
        this.loggedIn = false;
      }
    });
  }

  toggleCollapse() {
    this.show = !this.show;
  }

  logout() {
    this.auth.signOut();
    this.router.navigate(['/login']);
  }

}
