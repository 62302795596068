import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CreateCategoryComponent} from './create-category/create-category.component';
import {FormsModule} from '@angular/forms';
import {CategoryRoutingModule} from './category-routing.module';
import {EditCategoryComponent} from './edit-category/edit-category.component';
import {ListCategoryComponent} from './list-category/list-category.component';

@NgModule({
  declarations: [
    CreateCategoryComponent,
    EditCategoryComponent,
    ListCategoryComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    CategoryRoutingModule
  ]
})
export class CategoryModule {
}
