import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule} from '@angular/forms';

import { CreateGroupComponent } from './create-group/create-group.component';
import { EditGroupComponent } from './edit-group/edit-group.component';
import { ListGroupComponent } from './list-group/list-group.component';

import { GroupsRoutingModule } from './groups-routing.module';

@NgModule({
  declarations: [CreateGroupComponent, EditGroupComponent, ListGroupComponent],
  imports: [
    CommonModule,
    FormsModule,
    GroupsRoutingModule
  ]
})
export class GroupsModule { }
