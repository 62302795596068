import {Injectable} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AnswerKeyIntensiveService {
  answerKeyIntensiveCollection: AngularFirestoreCollection<any>;

  constructor(private afs: AngularFirestore) {
    this.answerKeyIntensiveCollection = this.afs.collection('answerkeys-intensive');
  }

  getData(): Observable<any[]> {
    return this.answerKeyIntensiveCollection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data();
          return {id: a.payload.doc.id, ...data};
        });
      })
    );
  }

  getAnswerKey(id: string) {
    return this.afs.doc<any>(`answerkeys-intensive/${id}`);
  }

  createAnswerKey(data) {
    const answerKey = JSON.parse(JSON.stringify(data));
    console.log('answerKey in createAnswerKey: ',answerKey);
    return this.answerKeyIntensiveCollection.add(answerKey);
  }

  updateAnswerKey(id: string) {
    return this.afs.collection('answerkeys-intensive',(ref) => ref.where('uuid','==',id)).get();
  }

  deleteIntenseAnswerKey(id: string) {
    return this.afs.collection('answerkeys-intensive',(ref) => ref.where('uuid','==',id)).get();
  }
}
