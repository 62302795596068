import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AdminComponent} from './admin/admin.component';

import {AuthGuard} from '../core/auth.guard';

import {CreateIntensiveComponent} from './answer-key-intensive/create-intensive/create-intensive.component';
import {EditIntensiveComponent} from './answer-key-intensive/edit-intensive/edit-intensive.component';
import {ListIntensiveComponent} from './answer-key-intensive/list-intensive/list-intensive.component';

import {CreateAnswerKeyComponent} from '../answer-key/create-answer-key/create-answer-key.component';
import {EditAnswerKeyComponent} from '../answer-key/edit-answer-key/edit-answer-key.component';
import {ListAnswerKeyComponent} from '../answer-key/list-answer-key/list-answer-key.component';

import {TeachersComponent} from './teachers/teachers.component';

import {BranchesComponent} from './branches/branches.component';

// import {  } from './students/students.module'

const routes: Routes = [
  {
    path: 'admin',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: AdminComponent
      },
      {
        path: 'intensive/create',
        component: CreateIntensiveComponent
      },
      {
        path: 'intensive/edit',
        component: EditIntensiveComponent
      },
      {
        path: 'intensive/list',
        component: ListIntensiveComponent
      },
      {
        path: 'akey/create',
        component: CreateAnswerKeyComponent
      },
      {
        path: 'akey/edit',
        component: EditAnswerKeyComponent
      },
      {
        path: 'akey/list',
        component: ListAnswerKeyComponent
      },
      {
        path: 'teachers',
        component: TeachersComponent
      },
      {
        path: 'branches',
        component: BranchesComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule {
}
