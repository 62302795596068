import { Component, OnInit } from '@angular/core';
import { NotifyService } from '../../../core/notify.service';
import {Location} from '@angular/common';
import { v4 } from 'uuid';

import {Groups} from '../../../models/groups';
import { MineBranchService } from 'src/app/services/mine/mine-branch.service';
import { MineGroupsService } from 'src/app/services/mine/mine-groups.service';
import { MineLanguageService } from 'src/app/services/mine/mine-language.service';

@Component({
  selector: 'create-group',
  templateUrl: './create-group.component.html',
  styleUrls: ['./create-group.component.css']
})
export class CreateGroupComponent implements OnInit {

  groups: Groups;
  dupGroups: any;
  languages: any;
  selectedLanguage: any;
  branches: any;
  selectedBranch: any;
  isLoading = false;

  constructor(
    private _location: Location,
    private notify: NotifyService,
    private mineLanguageService: MineLanguageService,
    private mineBranchesService: MineBranchService,
    private mineGroupsService: MineGroupsService
  ) { 
    this.groups = new Groups();
    this.dupGroups = JSON.parse(JSON.stringify(this.groups));
  }

  ngOnInit(): void {
    this.languages = this.mineLanguageService.queryLanguage({});
    this.branches = this.mineBranchesService.queryBranch({});
    // console.log('this.branches: ',this.branches)
  }

  save(){
    if (!this.dupGroups.languageUuid) {
      this.notify.update('Группанын тилин танданыз', 'error');
      return;
    }
    if (!this.dupGroups.branchUuid) {
      this.notify.update('Филиалды танданыз', 'error');
      return;
    }

    this.dupGroups.uuid = v4();
    this.isLoading = true;

    this.dupGroups.title = this.groups.title;
    this.dupGroups.place = this.groups.place;
    this.dupGroups.totalStudentsInGroup = 0;

    console.log('this.dupGroups: ',this.dupGroups)

    this.mineGroupsService.createGroup(this.dupGroups)
    .then((result) => {
      this.isLoading = false;
      this.notify.update('Группа сакталды.', 'success');
      this._location.back();

    }).catch((error) => {
      console.log(error);
      this.notify.update('Группа сакталбады.' + error, 'error');
    });
  }

  onChangeLanguage(){
    this.dupGroups.languageUuid = this.selectedLanguage.uuid;
    this.dupGroups.languageTitle = this.selectedLanguage.title;
  }

  onChangeBranch() {
    this.dupGroups.branchUuid = this.selectedBranch.uuid;
    this.dupGroups.branchTitle = this.selectedBranch.displayName;
  }

  onCancel() {
    this._location.back();
  }
}
