import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExamResultsService {
  path = 'exam-results';
  // baseUrl = 'http://localhost:3001';
  // baseUrl = environment.baseURL;
  baseUrl = 'https://check-api.secom.kg';

  constructor(private http: HttpClient) {
  }

  query(body): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + `/api/v1/${this.path}/query`, body);
  }

  create(obj: any): any {
    // console.log(this.baseUrl + `/api/v1/${this.path}`)
    return this.http.post<any>(
      this.baseUrl + `/api/v1/${this.path}`,
      obj,
      {responseType: 'json', observe: 'body'}
    ).toPromise();
  }

  remove(uuid: any): any {
    return this.http.delete<any>(
      this.baseUrl + `/api/v1/${this.path}/${uuid}`,
      {responseType: 'json', observe: 'body'}
    ).toPromise();
  }

  // query(body): Observable<any[]> {
  //   return this.http.post<any[]>(`${environment.baseURL}/api/v1/${this.path}/query`, body);
  // }

  // create(obj: any): any {
  //   // console.log(`${environment.baseURL}/api/v1/${this.path}`)
  //   return this.http.post<any>(
  //     `${environment.baseURL}/api/v1/${this.path}`,
  //     obj,
  //     {responseType: 'json', observe: 'body'}
  //   ).toPromise();
  // }

  // remove(uuid: any): any {
  //   return this.http.delete<any>(
  //     `${environment.baseURL}/api/v1/${this.path}/${uuid}`,
  //     {responseType: 'json', observe: 'body'}
  //   ).toPromise();
  // }
}
