import {Component, OnInit} from '@angular/core';
import {CategoryService} from '../../../shared/category.service';
import {NotifyService} from '../../../core/notify.service';
import { MineCategoryService } from 'src/app/services/mine/mine-category.service';

// @ts-ignore
@Component({
  selector: 'list-category',
  templateUrl: './list-category.component.html',
  styleUrls: ['./list-category.component.scss']
})
export class ListCategoryComponent implements OnInit {
  isLoading = false;
  categories: any;
  selectedCategoryUuid = '';
  isModalActive: boolean = false;

  constructor(//private categoryService: CategoryService,
              private notifyService: NotifyService,
              private mineCategoryService: MineCategoryService) {
  }

  ngOnInit() {
    // this.categories = this.categoryService.getData();
    this.categories = this.mineCategoryService.queryCategory({});
    console.log('categories :',this.categories);
  }

  closeModal() {
    this.isModalActive = false;
  }
  showPopup(categoryUuid) {
    this.selectedCategoryUuid = categoryUuid;
    this.isModalActive = !this.isModalActive;
  }

  delete() {
    // console.log('selectedCategoryId: ',this.selectedCategoryUuid);

    this.isLoading = true;
    this.closeModal();

    this.mineCategoryService.removeByCategoryUuid(this.selectedCategoryUuid)
    .then((data)=>{
      this.isLoading = false;
      this.notifyService.update('Категория өчүрүлдү.', 'success');
      this.categories = this.mineCategoryService.queryCategory({});
      // console.log('done')
    })
    .catch((e)=>console.error(e))

    // this.categoryService.deleteCategory(this.selectedCategoryUuid)
    // .subscribe((QuerySnapshot) => {
    //   QuerySnapshot.forEach((doc) =>
    //     {
    //       doc.ref.delete().then(
    //         ()=>{
    //           this.isLoading = false;
    //           this.notifyService.update('Категория өчүрүлдү.', 'success');
    //         }
    //       ).catch((e)=>console.error(e))
    //     }
    //   )
    // });
  }

}
