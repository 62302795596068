import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import { CreateGroupComponent } from './create-group/create-group.component';
import { EditGroupComponent } from './edit-group/edit-group.component';
import { ListGroupComponent } from './list-group/list-group.component';

const routes: Routes = [
  {
    path: 'admin/groups',
    children: [
      {
        path: 'create',
        component: CreateGroupComponent
      },
      {
        path: 'edit',
        component: EditGroupComponent
      },
      {
        path: 'list',
        component: ListGroupComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GroupsRoutingModule {
}
