import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { MineStudentsService } from 'src/app/services/mine/mine-students.service';
import {NotifyService} from '../../../core/notify.service';
import { MineGroupsService } from 'src/app/services/mine/mine-groups.service';

@Component({
  selector: 'list-student',
  templateUrl: './list-student.component.html',
  styleUrls: ['./list-student.component.css']
})
export class ListStudentComponent implements OnInit {

  students: any;
  selectedStudentsUuid = '';
  groups: any;

  isModalActive: boolean = false;
  isLoading = false;
  uuid = '';
  groupTitle = '';
  groupUuid = '';
  constructor(
    private route: ActivatedRoute,
    private notifyService: NotifyService,
    private mineStudentsService: MineStudentsService,
    private mineGroupsService: MineGroupsService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.uuid = params.id;
      if (this.uuid) {

        this.students = this.mineStudentsService.queryStudents({'groupUuid':this.uuid}).toPromise()
          .then((data)=>{
            return data;
          })
          .catch()
        
        this.groups = this.mineGroupsService.queryGroup({'uuid':this.uuid}).toPromise()
        .then((data)=>{
          this.groupTitle = data[0].title;
          this.groupUuid = data[0].uuid;
          return data;
        })
        .catch();
      }
    });
  }

  delete() {

    this.isLoading = true;
    this.closeModal();

    this.mineStudentsService.removeByStudentsUuid(this.selectedStudentsUuid)
    .then((data)=>{

      this.mineGroupsService.queryGroup({'uuid':this.uuid}).toPromise()
      .then((yourGroup)=> {

        this.mineGroupsService.updateGroup({...yourGroup[0],'totalStudentsInGroup':Number(yourGroup[0].totalStudentsInGroup) - 1})
        .then((result) => {
          // console.log('result: ',result)
        }).catch();

      });

      this.isLoading = false;
      this.notifyService.update('Группа өчүрүлдү.', 'success');
      
      this.students = this.mineStudentsService.queryStudents({'groupUuid':this.uuid}).toPromise()
      .then((data)=>{
        return data;
      })
      .catch()
    })
    .catch((e)=>console.error(e))

  }

  showPopup(studentsUuid) {
    this.selectedStudentsUuid = studentsUuid;
    this.isModalActive = !this.isModalActive;
  }

  closeModal() {
    this.isModalActive = false;
  }
}
