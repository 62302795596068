import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../core/auth.service';

@Component({
  selector: 'home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  isLoading = true;
  isAdmin = false;
  notActivated = true;

  constructor(private auth: AuthService) {

    this.auth.user.subscribe((data) => {
      if (data) {
        this.notActivated = !data.activated;
      }
      if (data && data.role === 'Admin') {
        this.isAdmin = true;
      }
      this.isLoading = false;
    });

  }

  ngOnInit() {
  }

}
