import { Component, OnInit } from '@angular/core';

import { MineGroupsService } from 'src/app/services/mine/mine-groups.service';
import {NotifyService} from '../../../core/notify.service';

@Component({
  selector: 'list-group',
  templateUrl: './list-group.component.html',
  styleUrls: ['./list-group.component.css']
})
export class ListGroupComponent implements OnInit {

  groups: any;
  selectedGroupsUuid = '';
  isModalActive: boolean = false;
  isLoading = false;

  constructor(
    private notifyService: NotifyService,
    private mineGroupsService: MineGroupsService

  ) { }

  ngOnInit(): void {
    this.groups = this.mineGroupsService.queryGroup({});
  }

  delete() {

    this.isLoading = true;
    this.closeModal();

    this.mineGroupsService.removeByGroupsUuid(this.selectedGroupsUuid)
    .then((data)=>{
      this.isLoading = false;
      this.notifyService.update('Группа өчүрүлдү.', 'success');
      this.groups = this.mineGroupsService.queryGroup({});
      // console.log('done')
    })
    .catch((e)=>console.error(e))

  }

  showPopup(groupsUuid) {
    this.selectedGroupsUuid = groupsUuid;
    this.isModalActive = !this.isModalActive;
  }

  closeModal() {
    this.isModalActive = false;
  }
}
