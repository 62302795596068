import {Component, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {IntenseStudent, Student} from '../../models/student';
import {ParseGeneralService} from '../general-check/parse-general.service';
import {ApiClientService} from '../../shared/api-client.service';
import {AnswerKeyIntensiveService} from '../../shared/answer-key-intensive.service';
import saveAs from 'file-saver';

@Component({
  selector: 'intensive-check',
  templateUrl: './intensive-check.component.html',
  styleUrls: ['./intensive-check.component.scss']
})
export class IntensiveCheckComponent implements OnInit {

  host = environment.baseURL;
  generateExcelLink = '/api/generate-intense-excel';
  file: any;
  fileData: any;
  students: Student[] = [];

  mainPageLink = '';
  downloadLinkIsLoading = false;
  documentSubmitted = false;

  answerKeys: any = [];
  selectedAnswerKey: any;
  selectedCategory: any;

  constructor(private parserService: ParseGeneralService,
              private answerKeyService: AnswerKeyIntensiveService,
              private apiClientService: ApiClientService,
  ) {
  }

  ngOnInit() {
    this.answerKeys = this.answerKeyService.getData();
  }

  fileChanged(e) {
    this.file = e.target.files[0];
  }

  isValid() {
    return this.file && this.selectedAnswerKey;
  }

  uploadDocument() {

    this.downloadLinkIsLoading = true;
    this.documentSubmitted = true;
    const fileReader = new FileReader();
    fileReader.readAsText(this.file);

    fileReader.onload = (e) => {
      this.parseFileContent(fileReader.result.toString());
    };
  }

  private parseIntense(row: string) {
    const student = new IntenseStudent();
    const charCodeMap = {
      65: 1040,
      66: 1041,
      86: 1042,
      71: 1043,
      68: 1044,
      69: 1045,
      74: 1046,
      90: 1047,
      73: 1048,
      89: 1049,
      75: 1050,
      76: 1051,
      77: 1052,
      78: 1053,
      79: 1054,
      80: 1055,
      82: 1056,
      83: 1057,
      84: 1058,
      85: 1059,
      70: 1060,
      72: 1061,
      42: 42,
      91: 1063, //CH
      36: 1064, //SH
      87: 1198,
      38: 1067, //yy
      125: 1068,  //miagkiy
      81: 1069,
      88: 1070,
      123: 1071,
      32: 32,
      0: 0
    };
    const surname = row.substr(0, 17).trim();
    const name = row.substr(17, 17).trim();
    const ParentName = row.substr(44, 19).trim();
    let kirilName = '';
    for (let i = 0; i < name.length; i++) {
      if (name[i]) {
        kirilName += String.fromCharCode(charCodeMap[name[i].charCodeAt(0)]);
      }
    }

    let kirilSurname = '';
    for (let i = 0; i < surname.length; i++) {
      if (surname[i]) {
        kirilSurname += String.fromCharCode(charCodeMap[surname[i].charCodeAt(0)]);
      }
    }

    let kirilParentName = '';
    for (let i = 0; i < ParentName.length; i++) {
      if (ParentName[i]) {
        kirilParentName += String.fromCharCode(charCodeMap[ParentName[i].charCodeAt(0)]);
      }
    }

    student.surname = kirilSurname;
    student.name = kirilName;
    student.parentsPhone = row.substr(34, 10).trim();
    student.parentsNameSurname = kirilParentName;
    student.studentsPhone = row.substr(63, 10).trim();
    student.schoolNumber = row.substr(73, 2).trim();
    student.oblast = row.substr(75, 1).trim();
    student.rayon = row.substr(76, 1).trim();
    student.group = row.substr(77, 1).trim();
    student.answers = row.substr(78, 30).trim();
    return student;
  }

  /**
   * @param result
   * Parse file contents and produce students[] object.
   */
  private parseFileContent(result: string) {
    this.fileData = this.parserService.parse(result);
    this.fileData = this.fileData.filter((data) => data.length > 0);

    const students = this.fileData.map((row) => this.parseIntense(row));
    const charCodeMap = {
      1040: 65,
      1041: 66,
      1042: 67,
      1043: 68,
      1044: 69,
    };

    const evaluatedStudents = students.map((student) => {
      let points = '';
      let totalPoints = 0;
      const answer = student.answers;
      const group = student.group === 'R' ? 'ru' : 'kg';
      const jooptor = this.selectedAnswerKey[group].jooptor;
      jooptor.forEach((value, index) => {
        if (answer.charAt(index) && jooptor[index] && charCodeMap[jooptor[index].charCodeAt(0)] === answer.charAt(index).charCodeAt(0)) {
          points = points + '1';
          totalPoints++;
        } else {
          points = points + '0';
        }
      });

      student.points = points;
      student.totalPoints = totalPoints;
      return student;
    });

    this.submitToServer(evaluatedStudents);
  }

  private submitToServer(students: any) {
    this.downloadLinkIsLoading = true;

    this.apiClientService.post(this.generateExcelLink, {students}, {
      responseType: 'blob',
      contentType: 'application/zip'
    }).subscribe((data: any) => {
      saveAs(data, `Деңгээл аныктоо тестинин жыйынтыгы-${this.selectedAnswerKey.testNumber}.zip`);
      this.mainPageLink = data.link;
      this.downloadLinkIsLoading = false;
    });
  }
}
