import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {Location} from '@angular/common';

import { NotifyService } from 'src/app/core/notify.service';
import { Students } from 'src/app/models/students';
import { MineStudentsService } from 'src/app/services/mine/mine-students.service';
import { MineGroupsService } from 'src/app/services/mine/mine-groups.service';

@Component({
  selector: 'edit-student',
  templateUrl: './edit-student.component.html',
  styleUrls: ['./edit-student.component.css']
})
export class EditStudentComponent implements OnInit {

  students:  Students;
  dupStudents: any;
  groups: any;
  selectedGroup: any = {uuid: ''};
  isLoading = false;
  uuid = '';
  // branch:any = [];

  constructor(
    private route: ActivatedRoute,
    private _location: Location,
    private notify: NotifyService,
    private mineStudentsService: MineStudentsService,
    private mineGroupsService: MineGroupsService,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.uuid = params.id;
      if (this.uuid) {

        this.mineGroupsService.queryGroup({}).toPromise()
          .then((data)=>{
            this.groups = data;
            // console.log('data: ',data)
            this.mineStudentsService.queryStudents({'uuid':this.uuid}).toPromise()
              .then((data1)=> {
                // console.log('data[0]: ',data1)
                this.students = data1[0];
                this.dupStudents = JSON.parse(JSON.stringify(this.students));
                
                this.selectedGroup = this.groups.find((br) => {
                  return this.students.groupUuid === br.uuid;
                })                
                // console.log('this.selectedGroup: ',this.selectedGroup)
              })
              .catch((e)=>console.error(e));
              
              // console.log('data: ',data)
          })
          .catch()
      } // if case
    });
  }
  
  setNecessaryValues(){
    // if(this.dupStudents.groupUuid != this.selectedGroup.uuid){
      this.dupStudents.name = this.students.name;
      this.dupStudents.surname = this.students.surname;
      this.dupStudents.phone = this.students.phone;
      this.dupStudents.school = this.students.school;
    // }
  }

  save() {
    if (!this.dupStudents.groupUuid) {
      this.notify.update('Группаны танданыз', 'error');
      return;
    }

    this.isLoading = true;
    this.setNecessaryValues();
    // console.log('students: ',this.students)
    // console.log('dupStudents: ',this.dupStudents)

    this.mineStudentsService.updateStudents(this.dupStudents)
    .then((result) => {

      if(this.students.groupUuid != this.dupStudents.groupUuid){

        this.mineGroupsService.queryGroup({'uuid':result.groupUuid}).toPromise()
        .then((myGroup)=> {

          this.mineGroupsService.updateGroup({...myGroup[0],'totalStudentsInGroup':Number(myGroup[0].totalStudentsInGroup) + 1})
          .then((result) => {
            // console.log('result: ',result)
          }).catch();

        });

        this.mineGroupsService.queryGroup({'uuid':this.students.groupUuid}).toPromise()
        .then((yourGroup)=> {

          this.mineGroupsService.updateGroup({...yourGroup[0],'totalStudentsInGroup':Number(yourGroup[0].totalStudentsInGroup) - 1})
          .then((result) => {
            // console.log('result: ',result)
          }).catch();

        });

      }

      this.isLoading = false;
      this.notify.update('Студенттин маалыматтары сакталды.', 'success');
      this._location.back();
      
    }).catch((error) => {
      console.log(error);
    });
  }

  onCancel() {
    this._location.back();
  }

  onChange() {
    this.dupStudents.groupUuid = this.selectedGroup.uuid;
    this.dupStudents.groupTitle = this.selectedGroup.title;
  }

}
