export class User {
  // id: string;
  uid: string;
  displayName: string;
  email: string;
  activated: boolean;
  role: string;
  branchId: string;
  createdAt: Date;
}
