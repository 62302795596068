import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {CreateStudentComponent} from './create-student/create-student.component';
import {EditStudentComponent} from './edit-student/edit-student.component';
import {ListStudentComponent} from './list-student/list-student.component';

const routes: Routes = [
  {
    path: 'admin/students',
    children: [
      {
        path: 'create',
        component: CreateStudentComponent
      },
      {
        path: 'edit',
        component: EditStudentComponent
      },
      {
        path: 'list',
        component: ListStudentComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StudentRoutingModule {
}
