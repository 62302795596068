import {Component, OnInit} from '@angular/core';
// import {CategoryService} from '../../../shared/category.service';
import {Category} from '../../../models/category';
// import {AnswerKeyService} from '../../../shared/answer-key.service';
import {NotifyService} from '../../../core/notify.service';
import {ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import { MineCategoryService } from 'src/app/services/mine/mine-category.service';
import { MineAnswerkeysService } from 'src/app/services/mine/mine-answerkeys.service';

// @ts-ignore
@Component({
  selector: 'edit-category',
  styleUrls: ['./edit-category.component.scss'],
  templateUrl: './edit-category.component.html'
})
export class EditCategoryComponent implements OnInit {

  objectKeys = Object.keys;
  predmetter = ['math', 'analogiya', 'okuuTushunuu', 'grammatika'];
  predmetterName = ['Математика', 'АНАЛОГИЯЛАР ЖАНА СҮЙЛӨМДӨРДҮ ТОЛУКТОО',
    'ЭНЕ ТИЛИНДЕ ОКУУ ЖАНА ТҮШҮНҮҮ', 'ЭНЕ ТИЛИНИН ПРАКТИКАЛЫК ГРАММАТИКАСЫ'];
  predmetterNameRu = ['Математика', 'АНАЛОГИИ И ДОПОЛНЕНИЕ ПРЕДЛОЖЕНИЙ', 'ЧТЕНИЕ И ПОНИМАНИЕ', 'ПРАКТИЧЕСКАЯ ГРАММАТИКА РУССКОГО ЯЗЫКА'];
  orderGrammatika = ['leksika', 'morfologiya', 'sintaksis', 'stilistika', 'kepMadaniyaty', 'keptinTurloru'];

  category: Category;
  dupCategory: any;
  selectedAnswerKey: any = {uid: ''};
  answerKeys: any = [];
  isSaving = false;
  uuid = '';

  constructor(//private answerKeyService: AnswerKeyService,
              //private categoryService: CategoryService,
              private route: ActivatedRoute,
              private _location: Location,
              private notify: NotifyService,
              private mineCategoryService: MineCategoryService,
              private mineAnswerKeysService: MineAnswerkeysService) {
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.uuid = params.id;
      if (this.uuid) {

        this.mineAnswerKeysService.queryAnswerkeys({}).toPromise()
          .then((data)=>{
            this.answerKeys = data;

            this.mineCategoryService.queryCategory({'uuid':this.uuid}).toPromise()
              .then((data1)=> {
                console.log('data[0]: ',data1)
                this.category = data1[0];
                this.dupCategory = JSON.parse(JSON.stringify(this.category));
                
                this.selectedAnswerKey = this.answerKeys.find((akey) => {
                  return this.category.answerKeyUuid === akey.uuid;
                })
              })
              .catch((e)=>console.error(e));
              console.log('this.selectedAnswerKey: ',this.selectedAnswerKey)
              console.log('data: ',data)
          })
          .catch()

        // this.answerKeyService.getData().subscribe((data) => {
        //   this.answerKeys = data;
        //   this.categoryService.getCategory(this.id).valueChanges().subscribe((data1) => {
        //     this.category = data1;
        //     this.dupCategory = JSON.parse(JSON.stringify(this.category));
        //     this.selectedAnswerKey = this.answerKeys.find((akey) => {
        //       return this.category.answerKeyId === akey.id;
        //     });
        //   });
        // });
      }
    });

  }

  onSearchChange(searchValue: string, type: string, key: string, jj, ii, lang: string) {
    // [(ngModel)]="this.category.kg.math[keys].subCategories[jj].questions[ii]"
    this.dupCategory[lang][type][key].subCategories[jj].questions[ii] = searchValue;
  }

  onTopicChange(topicName: string, type: string, key: string, jj, lang: string) {
    // [(ngModel)]="this.category.kg.math[keys].subCategories[jj].questions[ii]"
    this.dupCategory[lang][type][key].subCategories[jj].name = topicName;
  }

  saveUpdateCategory() {
    if (!this.dupCategory.answerKeyUuid) {
      this.notify.update('Тестти танданыз', 'error');
      return;
    }

    this.removeUnusedFields();

    this.isSaving = true;

    this.setNecessaryValues();

    this.mineCategoryService.updateCategory(this.dupCategory)
    .then((result) => {
      console.log('updated category in edit category: ',result);
      console.log('this.selectedAnswerKey in saveUpdateCategory(): ', this.selectedAnswerKey);
      this.mineAnswerKeysService.updateAnswerkeys(this.selectedAnswerKey)
        .then((data)=>{
          this.isSaving = false;
          this.notify.update('Категориялар сакталды.', 'success');
          console.log('updated answerkeys in edit category: ',data);

          this._location.back();
        })
        .catch((e)=>console.error(e));
      
    }).catch((error) => {
      console.log(error);
    });
    
    // this.categoryService.updateCategory(this.id, this.dupCategory)
    //   .then((result) => {
    //     this.isSaving = false;
    //     this.notify.update('Категориялар сакталды.', 'success');
    //     this.answerKeyService
    //       .getAnswerKey(this.dupCategory.answerKeyId)
    //       .update({categoryId: this.id})
    //       .then(() => {});
    //     this._location.back();
    //   }).catch((error) => {
    //   console.log(error);
    //   this.notify.update('Категориялар сакталбады.' + error, 'error');
    // });

  }

  setNecessaryValues(){
    if(this.dupCategory.answerKeyUuid != this.selectedAnswerKey.uuid){
      this.dupCategory.answerKeyName = this.selectedAnswerKey.testName;
      this.dupCategory.answerKeyNumber = this.selectedAnswerKey.testNumber;
      this.dupCategory.answerKeyUuid = this.selectedAnswerKey.uuid;
      this.selectedAnswerKey.categoryUuid = this.dupCategory.uuid;
    }
  }

  onCancel() {
    this._location.back();
  }

  onChange() {
    if(this.dupCategory.answerKeyUuid != this.selectedAnswerKey.uuid){
      // this.dupCategory.answerKeyName = this.selectedAnswerKey.testName;
      // this.dupCategory.answerKeyNumber = this.selectedAnswerKey.testNumber;
      // this.dupCategory.answerKeyUuid = this.selectedAnswerKey.uuid;
    }
  }

  private removeUnusedFields() {
    ['kg', 'ru'].forEach((group) => {
      this.predmetter.forEach((predmet) => {
        Object.keys(this.dupCategory[group][predmet]).forEach((subcat) => {
          this.dupCategory[group][predmet][subcat].subCatNames = null;
        });
      });
    });
  }

}
