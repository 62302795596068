import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MineBranchService {

  path = 'branches';
  baseUrl = 'https://check-api.secom.kg';
  // baseUrl = 'http://localhost:4001';
  // baseUrl = environment.baseURL;

  constructor(private http: HttpClient) {
  }

  queryBranch(body): Observable<any> {
    return this.http.post<any>(this.baseUrl + `/api/v1/${this.path}/query`, body);
  }

  createBranch(obj: any): any {

    console.log(this.baseUrl + `/api/v1/${this.path}`)

    return this.http.post<any>(
      this.baseUrl + `/api/v1/${this.path}`,
      obj,
      {responseType: 'json', observe: 'body'}
    ).toPromise();
  }

  // removeByExamId(examId): any {
  //   return this.http.delete<any[]>(this.baseUrl + `/api/v1/${this.path}/examId/${examId}`).toPromise();
  // }

  removeByBranchUuid(branchId): any {
    return this.http.delete<any[]>(this.baseUrl + `/api/v1/${this.path}/branchUuid/${branchId}`).toPromise();
  }

  updateBranch(branch: any): any {
    return this.http.put<any[]>(this.baseUrl + `/api/v1/${this.path}`, branch).toPromise();
  }

  // queryBranch(body): Observable<any> {
  //   return this.http.post<any>(`${environment.baseURL}/api/v1/${this.path}/query`, body);
  // }

  // createBranch(obj: any): any {

  //   console.log(`${environment.baseURL}/api/v1/${this.path}`)

  //   return this.http.post<any>(
  //     `${environment.baseURL}/api/v1/${this.path}`,
  //     obj,
  //     {responseType: 'json', observe: 'body'}
  //   ).toPromise();
  // }

  // // removeByExamId(examId): any {
  // //   return this.http.delete<any[]>(`${environment.baseURL}/api/v1/${this.path}/examId/${examId}`).toPromise();
  // // }

  // removeByBranchUuid(branchId): any {
  //   return this.http.delete<any[]>(`${environment.baseURL}/api/v1/${this.path}/branchUuid/${branchId}`).toPromise();
  // }

  // updateBranch(branch: any): any {
  //   return this.http.put<any[]>(`${environment.baseURL}/api/v1/${this.path}`, branch).toPromise();
  // }
}
