import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ParseGeneralService {

  constructor() { }

  /**
   * it will receive raw data as a string and returns array of row
   * @param result
   */
  parse(result: string) {
    const parsedResultAsArray = result.split('\r\n');
    return parsedResultAsArray;
  }
}
