import {Component, OnInit} from '@angular/core';
import {AnswerKeyIntensive} from '../../../models/answer-key';
import {NotifyService} from '../../../core/notify.service';
import {AnswerKeyIntensiveService} from '../../../shared/answer-key-intensive.service';
import {Location} from '@angular/common';
import { MineAnswerKeyIntensiveServiceService } from 'src/app/services/mine/mine-answer-key-intensive-service.service';
import { v4 } from 'uuid';

@Component({
  selector: 'create-intensive',
  templateUrl: './create-intensive.component.html',
  styleUrls: ['./create-intensive.component.scss']
})
export class CreateIntensiveComponent implements OnInit {
  isLoading = false;
  answerKeyIntensive: AnswerKeyIntensive = new AnswerKeyIntensive();
  mineAnswerKeyIntensive: AnswerKeyIntensive = new AnswerKeyIntensive();

  constructor(private notify: NotifyService,
              private _location: Location,
              private answerKeyIntensiveService: AnswerKeyIntensiveService,
              private mineAnswerKeyIntensiveService: MineAnswerKeyIntensiveServiceService) {
  }

  onSelect(variant: string, lang: string, testType: string, index: any): void {
    this.answerKeyIntensive[lang][testType][index] = variant;
  }

  ngOnInit() {
  }

  save() {
    this.answerKeyIntensive.uuid = v4();
    this.isLoading = true;

    this.answerKeyIntensiveService.createAnswerKey(this.answerKeyIntensive)
      .then((result) => {
        this.isLoading = false;
        this.notify.update('Жооптор сакталды.', 'success');
        this._location.back();
      }).catch((error) => {
        console.log(error);
      });

    this.mineAnswerKeyIntensiveService.createAnswerKey(this.answerKeyIntensive)
      .then((result) => {
        console.log('done');
      }).catch((error) => {
        console.log(error);
      });
  }

  onCancel() {
    this._location.back();
  }

}
