import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {NotifyService} from '../../core/notify.service';
// import {BranchService} from '../../shared/branch.service';
import {Branch} from '../../models/branch.model';
import { MineBranchService } from '../../services/mine/mine-branch.service'
import { v4 } from 'uuid';

@Component({
  selector: 'branches',
  templateUrl: './branches.component.html',
  styleUrls: ['./branches.component.css']
})
export class BranchesComponent implements OnInit {
  branches: Observable<Branch>;
  branch = new Branch();
  selectedBranch = new Branch();

  mineBranches: Observable<any>;
  mineBranch = new Branch();
  selectedMineBranch = new Branch();
  initialBranches: Branch[];

  branchName = '';
  isLoading: boolean = false;
  isCreateModalActive: boolean = false;
  isEditModalActive: boolean = false;
  isDeleteModalActive: boolean = false;
  uuid: string;

  constructor(private mineBranchesService: MineBranchService,
              //private branchesService: BranchService,
              private notifyService: NotifyService) {
  }

  ngOnInit() {
    // this.branches = this.branchesService.getData();
    this.branches = this.mineBranchesService.queryBranch({});
    // console.log('branches: ', this.branches)
  }

  updateBranch(branchName) {
    // console.log('branchName in update: ',branchName);
    this.selectedBranch.displayName = branchName;
    const branch = this.selectedBranch;
    console.log('branch: ',branch)

    // this.mineBranch.uuid = this.uuid;
    this.mineBranchesService.updateBranch(branch)
      .then((data) => {
        this.isLoading = false;
        this.notifyService.update('Сакталды.', 'success');
        this.closeModal();
        console.log('mongodb updateBranch: ',data)
      })
      .catch(err => {
        console.log(err);
      });
    
    // this.branchesService.updateBranch(branch.uuid, branch)
    // .subscribe((QuerySnapshot) => {
    //   QuerySnapshot.forEach((doc) =>
    //     {
    //       doc.ref.update(branch)
    //         .then(
    //         ()=>{
    //           this.isLoading = false;
    //           this.notifyService.update('Сакталды.', 'success');
    //           this.closeModal();
    //         }
    //       ).catch((err) => {
    //         console.log(err)
    //         this.notifyService.update('Ката чыкты', 'error');
    //       });
    //     }
    //   )
    // });
       
    // .then((data) => {
    //   this.notifyService.update('Сакталды.', 'success');
    //   this.closeModal();
    //   console.log('firebase updateBranch: ',data)
    // }).catch((err) => {
    //   this.notifyService.update('Ката чыкты', 'error');
    // });
  }

  createBranch(mineBranchName: string) {
    this.uuid = v4();
    this.branch.uuid = this.uuid;
    this.branch.displayName = mineBranchName;
    this.branch.createdAt = new Date();
    this.isLoading = true;
    this.closeModal();
        
    this.mineBranchesService.createBranch(this.branch)
      .then((result) => {
        this.isLoading = false;
        this.notifyService.update('Филиал ийгиликтүү кошулду.', 'success');
        console.log('mongoDb: ',result);
        this.branches = this.mineBranchesService.queryBranch({});
      }).catch((error) => {
        console.log(error);
      });

    //   this.branchesService.createBranch(this.branch)
    //   .then((result) => {
    //     this.isLoading = false;
    //     this.notifyService.update('Филиал ийгиликтүү кошулду.', 'success');
    //     console.log('Firebase: ',result);
    //   }).catch((error) => {
    //   console.log(error);
    // });
  }

  showCreatePopup() {
    this.isCreateModalActive = !this.isCreateModalActive;
  }

  showUpdatePopup(branch) {
    this.selectedBranch = branch;
    this.branchName = this.selectedBranch.displayName;
    this.isEditModalActive = !this.isEditModalActive;
  }

  showDeletePopup(branch) {
    this.selectedBranch = branch;
    this.isDeleteModalActive = !this.isDeleteModalActive;
  }

  deleteBranch() {
    this.isLoading = true;
    this.closeModal();

    this.mineBranchesService.removeByBranchUuid(this.selectedBranch.uuid)
    .then((result) => {
      console.log('delete mongodb: ',result);
      this.isLoading = false;
      this.notifyService.update('Филиал өчүрүлдү.', 'success');
      this.branches = this.mineBranchesService.queryBranch({});
    }).catch((error) => {
      console.log(error);
    });

    // this.branchesService.deleteBranch(this.selectedBranch.uuid)
    //   .subscribe((QuerySnapshot) => {
    //     QuerySnapshot.forEach((doc) =>
    //       {
    //         doc.ref.delete().then(
    //           ()=>{
    //             this.isLoading = false;
    //             this.notifyService.update('Филиал өчүрүлдү.', 'success');
    //           }
    //         ).catch((e)=>console.error(e))
    //       }
    //     )
    //   });

  }

  closeModal() {
    this.isCreateModalActive = false;
    this.isEditModalActive = false;
    this.isDeleteModalActive = false;
  }
}
