import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MineAnswerKeyIntensiveServiceService {

  path = 'answerkeys-intensive';
  // baseUrl = 'http://localhost:3001';
  // baseUrl = environment.baseURL;
  baseUrl = 'https://check-api.secom.kg';

  constructor(private http: HttpClient) {
  }

  queryAnswerKey(body): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + `/api/v1/${this.path}/query`, body);
  }

  createAnswerKey(obj: any): any {

    console.log(this.baseUrl + `/api/v1/${this.path}`)

    return this.http.post<any>(
      this.baseUrl + `/api/v1/${this.path}`,
      obj,
      {responseType: 'json', observe: 'body'}
    ).toPromise();
  }

  removeByAnswerKeyUuid(intensiveUuid: any): any {
    return this.http.delete<any[]>(this.baseUrl + `/api/v1/${this.path}/intensiveUuid/${intensiveUuid}`).toPromise();
  }

  updateAnswerKey(answerkeys: any): any {
    return this.http.put<any[]>(this.baseUrl + `/api/v1/${this.path}`, answerkeys).toPromise();
  }

  // queryAnswerKey(body): Observable<any[]> {
  //   return this.http.post<any[]>(`${environment.baseURL}/api/v1/${this.path}/query`, body);
  // }

  // createAnswerKey(obj: any): any {

  //   console.log(`${environment.baseURL}/api/v1/${this.path}`)

  //   return this.http.post<any>(
  //     `${environment.baseURL}/api/v1/${this.path}`,
  //     obj,
  //     {responseType: 'json', observe: 'body'}
  //   ).toPromise();
  // }

  // removeByAnswerKeyUuid(intensiveUuid: any): any {
  //   return this.http.delete<any[]>(`${environment.baseURL}/api/v1/${this.path}/intensiveUuid/${intensiveUuid}`).toPromise();
  // }

  // updateAnswerKey(answerkeys: any): any {
  //   return this.http.put<any[]>(`${environment.baseURL}/api/v1/${this.path}`, answerkeys).toPromise();
  // }
}
