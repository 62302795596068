import {BrowserModule, BrowserTransferStateModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {environment} from '../environments/environment';
// Firestarter App Modules
import {CoreModule} from './core/core.module';
import {UiModule} from './ui/ui.module';
// @angular/fire/ Modules
import {AngularFireModule} from '@angular/fire';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {AngularFireStorageModule} from '@angular/fire/storage';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFireFunctionsModule} from '@angular/fire/functions';
import {AnswerKeyModule} from './answer-key/answer-key.module';
import {AdminModule} from './admin/admin.module';
import {HttpClientModule} from '@angular/common/http';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';



// IMPORTANT
// Add your own project credentials to environments/*.ts

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    BrowserTransferStateModule,
    HttpClientModule,
    AnswerKeyModule,
    AdminModule,
    AppRoutingModule,
    CoreModule,
    UiModule,
    AngularFireModule.initializeApp(environment.firebase, 'check-secom'),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    AngularFireAnalyticsModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
