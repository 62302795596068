import {Component, OnInit} from '@angular/core';
import {AnswerKeyIntensiveService} from '../../../shared/answer-key-intensive.service';
import {NotifyService} from '../../../core/notify.service';
import { MineAnswerKeyIntensiveServiceService } from 'src/app/services/mine/mine-answer-key-intensive-service.service';

@Component({
  selector: 'list-intensive',
  templateUrl: './list-intensive.component.html',
  styleUrls: ['./list-intensive.component.scss']
})
export class ListIntensiveComponent implements OnInit {
  isLoading = false;
  answerKeysIntensive: any;
  isModalActive = false;
  selectedAnswerKeyUuid = '';

  constructor(private answerKeyIntensiveService: AnswerKeyIntensiveService,
              private notifyService: NotifyService,
              private mineAnswerKeyIntensiveService: MineAnswerKeyIntensiveServiceService) {
  }

  ngOnInit() {
    this.answerKeysIntensive = this.answerKeyIntensiveService.getData();
  }

  closeModal() {
    this.isModalActive = false;
  }

  showPopup(answerKeyUuid) {
    this.selectedAnswerKeyUuid = answerKeyUuid;
    this.isModalActive = !this.isModalActive;
  }

  delete() {
    // console.log('selectedAnswerKeyUuid in delete: ',this.selectedAnswerKeyUuid)
    this.isLoading = true;
    this.closeModal();
    this.answerKeyIntensiveService.deleteIntenseAnswerKey(this.selectedAnswerKeyUuid)
      .subscribe((QuerySnapshot) => {
        QuerySnapshot.forEach((doc) =>
          {
            doc.ref.delete().then(
              ()=>{
                this.isLoading = false;
                this.notifyService.update('Интенсивдин жооптору өчүрүлдү.', 'success');
              }
            ).catch((e)=>console.error(e))
          }
        )
      });

    this.mineAnswerKeyIntensiveService.removeByAnswerKeyUuid(this.selectedAnswerKeyUuid)
      .then((data)=>console.log('done'))
      .catch((e)=>console.error(e))
  }
}
