import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {AdminRoutingModule} from './admin-routing.module';
import {AdminComponent} from './admin/admin.component';
import {CategoryModule} from './category/category.module';
import {TeachersComponent} from './teachers/teachers.component';

import {CreateIntensiveComponent} from './answer-key-intensive/create-intensive/create-intensive.component';
import {ListIntensiveComponent} from './answer-key-intensive/list-intensive/list-intensive.component';
import {EditIntensiveComponent} from './answer-key-intensive/edit-intensive/edit-intensive.component';

import { BranchesComponent } from './branches/branches.component';

import { StudentsModule } from './students/students.module';
import { GroupsModule } from './groups/groups.module';

@NgModule({
  declarations: [
    AdminComponent,
    TeachersComponent,
    CreateIntensiveComponent,
    ListIntensiveComponent,
    EditIntensiveComponent,
    BranchesComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    AdminRoutingModule,
    CategoryModule,
    StudentsModule,
    GroupsModule
  ]
})
export class AdminModule {
}
