import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {AuthService} from '../../core/auth.service';
import {Location} from '@angular/common';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
// import {BranchService} from '../../shared/branch.service';
import { MineBranchService } from 'src/app/services/mine/mine-branch.service';

type UserFields = 'email' | 'password' | 'displayName';
type FormErrors = { [u in UserFields]: string };

@Component({
  selector: 'user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
})
export class UserFormComponent implements OnInit {
  branches: Observable<any>;
  userForm: FormGroup;
  newUser = false; // to toggle login or signup form
  passReset = false; // set to true when password reset is triggered
  formErrors: FormErrors = {
    'displayName': '',
    'email': '',
    'password': '',
  };
  validationMessages = {
    'displayName': {
      'required': 'displayName is required.',
    },
    'email': {
      'required': 'Email is required.',
      'email': 'Email must be a valid email',
    },
    'password': {
      'required': 'Password is required.',
      'pattern': 'Password must be include at one letter and one number.',
      'minlength': 'Password must be at least 4 characters long.',
      'maxlength': 'Password cannot be more than 40 characters long.',
    },
  };

  constructor(private fb: FormBuilder,
              private auth: AuthService,
              private router: Router,
              // private branchService: BranchService,
              private mineBranchesService: MineBranchService,
              private _location: Location) {
  }

  ngOnInit() {
    this.buildForm();
        // this.branches = this.branchesService.getData();
        this.branches = this.mineBranchesService.queryBranch({});
  }

  toggleForm() {
    this.newUser = !this.newUser;
  }

  signup() {
    const formValues = this.userForm.value;
    this.auth.emailSignUp(formValues['email'], formValues['password'], formValues['displayName'], formValues['branchId'])
      .then(() => {
        this.router.navigate(['/']);
      });
  }

  login() {
    this.auth.emailLogin(this.userForm.value['email'], this.userForm.value['password'])
      .then(() => {
        this.router.navigate(['/']);
      }).catch((err) => {
      // console.log(err);
    });
  }

  resetPassword() {
    this.auth.resetPassword(this.userForm.value['email'])
      .then(() => this.passReset = true);
  }

  buildForm() {
    this.userForm = this.fb.group({
      'displayName': [''],
      'email': ['', [
        Validators.required,
        Validators.email,
      ]],
      'branchId': [''],
      'password': ['', [
        // Validators.pattern('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$'),
        Validators.minLength(6),
        Validators.maxLength(25),
      ]],
    });

    this.userForm.valueChanges.subscribe((data) => this.onValueChanged(data));
    this.onValueChanged(); // reset validation messages
  }

  // Updates validation state on form changes.
  onValueChanged(data?: any) {
    if (!this.userForm) {
      return;
    }
    const form = this.userForm;
    for (const field in this.formErrors) {
      if (Object.prototype.hasOwnProperty.call(this.formErrors, field) && (field === 'email' || field === 'password')) {
        // clear previous error message (if any)
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          if (control.errors) {
            for (const key in control.errors) {
              if (Object.prototype.hasOwnProperty.call(control.errors, key)) {
                this.formErrors[field] += `${(messages as { [key: string]: string })[key]} `;
              }
            }
          }
        }
      }
    }
  }
}
